const education = {
  title: {
    title: 'Education',
  },
  content: {
    list: [
      {
        time: <span>1989-1995</span>,
        desc: <span>Primary school in Berlin, Germany</span>,
      },
      {
        time: <span>1995-2002</span>,
        desc: <span>Grammar school in Berlin, Germany</span>,
      },
      {
        time: <span>2002-2008</span>,
        desc: <span>Studies of chemistry at University of Potsdam, Germany</span>,
      },
      {
        time: <span>2007-2008</span>,
        desc: <span>Diploma thesis in the group of Prof. P. Saalfrank at University of Potsdam, Germany</span>,
      },
      {
        time: <span>2008-2011</span>,
        desc: <span>Ph.D thesis in the group of Prof. P. Saalfrank at University of Potsdam, Germany</span>,
      },
      {
        time: <span>2016</span>,
        desc: <span>Educating class at Works gGmbH in Berlin, Germany</span>,
      },
      {
        time: <span>2018-2019</span>,
        desc: <span>Web development class at Digital Career Institute in Berlin, Germany</span>,
      },
    ],
  },
};

export default education;
