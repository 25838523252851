const work = {
  title: {
    title: 'Work Experience',
  },
  content: {
    list: [
      {
        time: <span>2008-2011</span>,
        desc: <span>Scientific coworker in the group of Prof. P. Saalfrank at University of Potsdam, Germany</span>,
      },
      {
        time: <span>2014-2016</span>,
        desc: <span>Scientific coworker in the group of Dr. J. C. Tremblay at Free University of Berlin, Germany</span>,
      },
      {
        time: <span>2019</span>,
        desc: <span>Internship in web development at Daizu GmbH/sleep.ink in Berlin, Germany</span>,
      },
      {
        time: <span>2020-2022</span>,
        desc: <span>Web developer at Daizu GmbH/sleep.ink in Berlin, Germany</span>,
      },
      {
        time: <span>since 2022</span>,
        desc: <span>Web developer at ~sedna GmbH in Berlin, Germany</span>,
      },
    ],
  },
};

export default work;
