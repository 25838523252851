const html = [
  {
    title: 'Fake birdwatching site with structural HTML elements',
    href: 'https://sklinkusch.github.io/01-Structuring-the-Web/',
    src: 'https://github.com/sklinkusch/01-Structuring-the-Web/raw/master/structuring-the-web.png',
  },
  {
    title: 'Basic information about myself',
    href: 'https://sklinkusch.github.io/02-css-basics/index.html',
    src: 'https://github.com/sklinkusch/02-css-basics/raw/master/css-basics.png',
  },
  {
    title: 'Fake letter layout',
    href: 'https://sklinkusch.github.io/03-letter-layout/letter.html',
    src: 'https://github.com/sklinkusch/03-letter-layout/raw/master/letter-layout.png',
  },
  {
    title: 'Page layout such as in a book or pdf file',
    href: 'https://sklinkusch.github.io/04-page-layout/',
    src: 'https://github.com/sklinkusch/04-page-layout/raw/master/page-layout.png',
  },
  {
    title: 'Fake blog page with blogposts',
    href: 'https://sklinkusch.github.io/06-blog-headlines/blog.html',
    src: 'https://github.com/sklinkusch/06-blog-headlines/raw/master/Screenshot.png',
  },
  {
    title: 'Fake instagram profile page',
    href: 'https://sklinkusch.github.io/07-instagram-flexbox/',
    src: 'https://github.com/sklinkusch/07-instagram-flexbox/raw/master/Screenshot.png',
  },
  {
    title: 'Webpage with a mega menu',
    href: 'https://sklinkusch.github.io/08-mega-menu/',
    src: 'https://github.com/sklinkusch/08-mega-menu/raw/master/Screenshot.png',
  },
  {
    title: 'Website with a responsive layout',
    href: 'https://sklinkusch.github.io/09-responsive-design/',
    src: 'https://github.com/sklinkusch/09-responsive-design/raw/master/Screenshot.png',
  },
  {
    title: 'Post-it notes on a dark background',
    href: 'https://sklinkusch.github.io/post-it/',
    src: 'https://github.com/sklinkusch/post-it/raw/master/Screenshot.png',
  },
  {
    title: 'Layout of an article from https://medium.com',
    href: 'https://sklinkusch.github.io/article-layout',
  },
];

type HtmlType = typeof html;
export { HtmlType };

export default html;
