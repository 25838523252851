const javascript = [
  {
    title: 'Fortune Teller Game Website',
    href: 'https://sklinkusch.github.io/fortune-js/',
    src: 'https://github.com/sklinkusch/fortune-js/raw/master/Screenshot_en.png',
  },
  {
    title: 'Animal Age Calculator',
    href: 'https://sklinkusch.github.io/JS-animal-age/',
    src: 'https://github.com/sklinkusch/JS-animal-age/raw/master/Screenshot.png',
  },
  {
    title: 'Quote of the Day',
    href: 'https://fbw-12.github.io/13-quote-of-the-day-sklinkusch/',
  },
  {
    title: 'Tip Calculator',
    href: 'https://fbw-12.github.io/14-tip-calculator-sklinkusch/',
  },
  {
    title: 'iTunes Search',
    href: 'https://fbw-12.github.io/16-the-sounds-of-classes/',
  },
  {
    title: 'GithubSearch',
    href: 'https://sklinkusch.github.io/Github-search/',
    src: 'https://github.com/sklinkusch/Github-search/raw/master/Screenshot.png',
  },
  {
    title: 'Coming Soon Countdown',
    href: 'https://fbw-12.github.io/18-coming-soon-landingpage-sklinkusch/',
  },
  {
    title: 'New Tab page with Github content',
    href: 'https://fbw-12.github.io/19-new-tab-github-sklinkusch/',
  },
  {
    title: 'Country information based on a search for the calling code',
    href: 'https://sklinkusch.github.io/country-codes/',
    src: 'https://github.com/sklinkusch/country-codes/raw/master/Screenshot.png',
  },
  {
    title: 'World weather page consuming data from DarkSky API',
    href: 'https://sklinkusch.github.io/JS-weather/',
    src: 'https://github.com/sklinkusch/JS-weather/raw/master/Screenshot.png',
  },
  {
    title: 'ToDo list page using event handlers',
    href: 'https://sklinkusch.github.io/architecture-eventlisteners/',
    src: 'https://github.com/sklinkusch/architecture-eventlisteners/raw/master/Screenshot.png',
  },
  {
    title: 'overview of bus and train departures at defined station in Berlin',
    href: 'https://sklinkusch.github.io/bvg-test/',
    src: 'https://raw.githubusercontent.com/sklinkusch/bvg-test/master/Screenshot.png',
  },
];

type JavascriptType = typeof javascript;
export { JavascriptType };

export default javascript;
