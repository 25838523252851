const react = [
  {
    title: 'information about many countries and dependent territories',
    href: 'https://country-db.vercel.app/',
    src: 'https://raw.githubusercontent.com/sklinkusch/country-db/master/Screenshot.png',
  },
  {
    title: 'chat mockup using React components',
    href: 'https://sklinkusch.github.io/nice-chat',
    src: 'https://raw.githubusercontent.com/sklinkusch/nice-chat/master/Screenshot.png',
  },
  {
    title: 'world time clock using React.js, Bootstrap, and React-Router',
    href: 'https://sklinkusch.github.io/react-clock',
    src: 'https://raw.githubusercontent.com/sklinkusch/react-clock/master/Screenshot.png',
  },
  {
    title: 'dashboard with a clock, a to-do-list, and a random background image',
    href: 'https://sklinkusch.github.io/react-dashboard',
    src: 'https://raw.githubusercontent.com/sklinkusch/react-dashboard/master/Screenshot.png',
  },
  {
    title: 'markdown editor page using React',
    href: 'https://sklinkusch.github.io/react-markdown-editor',
    src: 'https://raw.githubusercontent.com/sklinkusch/react-markdown-editor/master/Screenshot.png',
  },
  {
    title: 'a simple memo-list using React',
    href: 'https://sklinkusch.github.io/react-memolist',
    src: 'https://raw.githubusercontent.com/sklinkusch/react-memolist/master/Screenshot.png',
  },
  {
    title: 'a world weather page using React and Context',
    href: 'https://react-weather.sklinkusch.now.sh/',
    src: 'https://raw.githubusercontent.com/sklinkusch/react-weather/master/Screenshot.png',
  },
  {
    title: 'iTunes search using React',
    href: 'https://sound-of-react.sklinkusch.now.sh/',
    src: 'https://raw.githubusercontent.com/sklinkusch/sound-of-react/master/Screenshot.png',
  },
  {
    title: 'VBB timetable page using React',
    href: 'https://sklinkusch.github.io/VBB',
    src: 'https://github.com/sklinkusch/VBB/raw/master/Screenshot.png',
  },
];

type ReactType = typeof react;
export { ReactType };

export default react;
