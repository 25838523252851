import css from '../../images/CSS.3.svg.png';
import sass from '../../images/Sass_Logo_Color.png';
import bootstrap from '../../images/Boostrap_logo.png';
import themeUi from '../../images/theme-ui.png';
import styled from '../../images/styled-components.png';
import materialui from '../../images/materialUI.svg';

const styling = {
  title: 'Styling',
  content: [
    {
      name: 'Bootstrap',
      imageSrc: bootstrap,
      imageId: 'bootstrap',
      link: 'https://getbootstrap.com/',
      imgStyles: {
        width: '200px',
      },
    },
    {
      name: 'CSS3',
      imageSrc: css,
      imageId: 'css',
      link: 'https://www.w3.org/Style/CSS/',
      imgStyles: {
        width: '150px',
      },
    },
    {
      name: 'Material UI',
      imageSrc: materialui,
      imageId: 'material-ui',
      link: 'https://mui.com/material-ui/',
      imgStyles: {
        width: '200px',
      },
    },
    {
      name: 'Sass/SCSS',
      imageSrc: sass,
      imageId: 'sass',
      link: 'https://sass-lang.com/',
      imgStyles: {
        width: '200px',
      },
    },
    {
      name: 'Styled Components',
      imageSrc: styled,
      imageId: 'styledComponents',
      link: 'https://styled-components.com/',
      imgStyles: {
        width: '200px',
      },
    },
    {
      name: 'Theme UI',
      imageSrc: themeUi,
      imageId: 'themeUi',
      link: 'https://theme-ui.com/',
      imgStyles: {
        width: '200px',
      },
    },
  ],
};

export default styling;
