import { Theme } from 'theme-ui';

const makeTheme = <T extends Theme>(t: T) => t;

const theme = makeTheme({
  breakpoints: ['576px', '768px', '992px', '1200px'],
  container: ['540px', '720px', '960px', '1140px'],
  fonts: {
    body: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif`,
  },
  colors: {
    bblue: 'blue',
    ddarkgreen: '#005500',
    ggrey: '#212529',
    oorange: 'orange',
    wwhite: 'white',
  },
});

export default theme;
