import storybook from '../../images/Storybook.png';
import confluence from '../../images/Confluence.svg';

const documentation = {
  title: 'Documentation',
  content: [
    {
      name: 'Confluence',
      imageSrc: confluence,
      imageId: 'confluence',
      link: 'http://www.atlassian.com/software/confluence/',
      imgStyles: {
        width: '180px',
      },
    },
    {
      name: 'Storybook',
      imageSrc: storybook,
      imageId: 'storybook',
      link: 'https://storybook.js.org',
      imgStyles: {
        width: '180px',
      },
    },
  ],
};

export default documentation;
